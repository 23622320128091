@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;
// TODO: rewrite this stylesheet so this is not necessary:
// stylelint-disable no-descending-specificity

$globalNavbarHeight: 80px;

.scrollLink {
  // Adjusting scroll anchor down so header isn't blocked by page banner
  scroll-margin-top: 150px;
}

.scrollLink:focus {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    outline: 1px dotted;
  }
}

.article-body__grid--container {
  background-color: var(--article-body-grid-container-background);

  &.article-body__grid--container-live-blog {
    // overflow-x-hidden breaks sticky
    // this is a replacement for it
    max-width: 100vw;
  }

  &.article-body__grid--container-no-main-image {
    padding: var(--article-body-grid-container-no-main-padding);

    @include h.breakpoint-m-only {
      flex-direction: column;
    }
  }
}

body {
  .article_body__grid--shell-container {
    width: 100%;
    max-width: none;
    margin: 0;

    .layout-grid-item {
      border-width: 0;
    }

    & > .layout-grid-container:not(.article-body__last-section),
    & > .layout-grid-container.article-body__first-section {
      max-width: none;
    }

    .article-body {
      width: 100%;
      margin: 0;
    }
  }

  &:not(.shorthand-embed-body) {
    .article-body {
      &.article-body--custom-column {
        @include h.breakpoint-l-only {
          @include h.grid-custom-column(7.5);
          margin-left: 0;
        }
      }

      .article-body__section--wells-fargo__banner.article-body--custom-column {
        @include h.breakpoint-l-only {
          @include h.grid-custom-column(12);
          margin-left: 0;
        }

        @include h.breakpoint-m-only {
          margin-left: 0;
        }

        @include h.breakpoint-s-only {
          border: 0;
        }
      }
    }
  }
}

.article-body__right-rail--sticky {
  position: sticky;
  top: 112px;
}

.article-body__right-rail--recommended {
  display: none;

  @include h.breakpoint-l {
    display: inherit;
  }
}

.article-body__bottom-rail--recommended {
  display: inherit;

  @include h.breakpoint-l {
    display: none;
  }
}

.article-body__section {
  @include h.clearfix;

  @include h.breakpoint-l {
    display: flex;
    width: 100%;
  }
}

.article-body__last-section {
  margin-bottom: h.rem(48px);
}

// Endmarks should only appear at the end of article pages
body.articlePage {
  .article-body__last-section {
    .endmark::after,
    // The direct decsendant dev is for third party made articles that are embedded into our article shell.
    .embedded-endmark > div::after {
      background-image: var(--article-body-last-section-bg-image);
      background-size: var(--article-body-last-section-bg-image-width) var(--article-body-last-section-bg-image-height);
      background-repeat: no-repeat;
      display: inline-block;
      width: var(--article-body-last-section-bg-image-width);
      height: var(--article-body-last-section-bg-image-height);
      content: '';
      margin-left: var(--article-body-endmark-margin);
    }

    .sportsEndMark::after,
    // The direct decsendant dev is for third party made articles that are embedded into our article shell.
    .embedded-endmark > .sportsEndMark::after {
      display: block;
      background-image: var(--sports-article-body-last-section-bg-image);
      background-size: 165px 45px;
      height: 25px;
      width: 100%;
      margin-top: 40px;
    }

    ol.endmark::after,
    ul.endmark::after {
      margin-left: 0;
    }
  }

  .sports-article-top-badge::after {
    display: block;
    background-image: var(--sports-article-body-last-section-bg-image);
    background-size: 165px 45px;
    height: 25px;
    width: 100%;
    content: '';
    background-repeat: no-repeat;
    margin-bottom: 28px;
  }
}

.article-body {
  color: var(--article-body-color);
  font-family: var(--article-body-font-family);
  word-wrap: break-word;
  line-height: 1.5;

  @include h.responsive-variations(font-size, (s: 1rem, m: 1.125rem));

  body.select & {
    @include h.responsive-variations(font-size, (s: 15px, m: 17px));
    @include h.responsive-variations(line-height, (s: 24px, m: 29px));
  }

  body.today & {
    font-size: h.rem(20px);
  }

  ::selection,
  ::-moz-selection {
    background: var(--article-body-selection-color);
  }

  pre {
    font-family: var(--article-body-pre-font-family);
    line-height: var(--article-body-pre-leading);
    color: var(--article-body-pre-text-color);
  }

  .article-body__sponsored-by-text {
    font-family: var(--article-body-sponsored-by-font-family);
    line-height: var(--article-body-sponsored-by-leading);
    color: var(--article-body-sponsored-by-color);
    letter-spacing: h.rem(.5px);
    text-transform: uppercase;
  }

  // Remove global margin on ads and add margin when ad is not hidden.
  .ad-container.mb8 {
    margin: 0;

    .mps-slot:not(.mps-gpt-hidden) {
      margin: h.rem(48px) 0;
    }
  }

  .article-bookmark-menu,
  .article-social-share-top {
    margin: var(--article-body-article-social-share-top--margin);
  }

  .article-social-share-bottom {
    margin: var(--article-body-article-social-share-bottom--margin);
  }
}

.article-body__content {
  .body-graf {
    margin: h.rem(24px 0);

    > strong {
      color: var(--article-body-content-strong);
    }
  }

  .break-above::before {
    content: '\A';
    white-space: var(--break-above-before-white-space);
  }

  &--gated {
    @include h.gated-gradient;
  }

  // All headings
  .body-heading {
    line-height: 1em;
    clear: both;
    margin: var(--article-body-static-heading-margin);
    color: var(--article-body-static-heading-color);
    font-family: var(--article-body-heading-font-family);
    font-weight: var(--article-body-heading-font-weight);

    :not(h1.body-heading) {
      font-family: var(--article-body-heading-font-family);
    }

    > a {
      @include h.fade-on-hover();
    }

    a,
    a strong, {
      border-bottom: var(--article-body-heading-link-underline);
      padding-bottom: var(--article-body-heading-link-underline-padding);
      color: var(--article-body-heading-color);

      &:hover {
        color: var(--article-body-heading-link-hover-color);
        opacity: var(--article-body-heading-link-hover-opacity);
      }
    }
  }

  h1.body-heading {
    line-height: var(--article-body-heading-h1-line-height);

    @include h.responsive-variations(font-size, (s: h.rem(20px), m: h.rem(32px)));

    body.select & {
      font-size: h.rem(30px);
    }
  }

  h2.body-heading {
    font-size: var(--article-body-heading-h2-font-size);
    line-height: var(--article-body-heading-h2-line-height);
  }

  h3.body-heading {
    font-size: var(--article-body-heading-h3-font-size);
    line-height: var(--article-body-heading-h3-line-height);
  }

  h4.body-heading {
    font-size: var(--article-body-heading-h4-font-size);
    line-height: var(--article-body-heading-h4-line-height);
  }

  h5.body-heading {
    font-size: var(--article-body-heading-h5-font-size);
    line-height: var(--article-body-heading-h5-line-height);
  }

  h6.body-heading {
    font-size: var(--article-body-heading-h6-font-size);
    line-height: var(--article-body-heading-h6-line-height);
  }

  // Headings in page anchors
  .scrollLink .body-heading {
    clear: both;
    margin: h.rem(24px) 0;
    color: var(--article-body-anchor-heading-color);

    @include h.fade-on-hover();

    // first headline of the article should float around the newsletter signup.
    // the first child is the newsletter signup itself
    &:nth-child(2) {
      clear: none;
    }
  }

  .scrollLink h2.body-heading {
    margin: h.rem(48px 0 24px);
  }

  .body-ol {
    counter-reset: item;
    padding-left: 0;

    > li {
      position: relative;
      list-style-type: none;
      padding-left: h.rem(35px);
      margin: var(--article-body-ol-li-margin);

      &::before {
        color: var(--article-body-ol-number-color);
        content: counter(item) ' ';
        counter-increment: item;
        display: inline-block;
        font-family: var(--article-body-ol-font-family);
        font-size: var(--article-body-ol-font-size);
        font-weight: var(--article-body-ol-font-weight);
        line-height: 1;
        margin-left: h.rem(-35px);
        vertical-align: text-bottom;
        width: h.rem(35px);
      }

      &:nth-of-type(n+100) {
        padding-left: h.rem(45px);

        &::before {
          margin-left: h.rem(-45px);
          width: h.rem(45px);
        }
      }

      > p {
        margin: 0;
        display: inline-block;
        vertical-align: text-top;
      }
    }
  }

  .body-ul,
  .body-ol ul { // handle uls inside of ols the same way
    padding-left: var(--article-body-bullet-width);

    > li,
    ul > li {
      position: relative;
      margin-bottom: h.rem(24px);

      &::before {
        color: var(--article-body-bullet-color);
        background-repeat: no-repeat;
        content: '\2022';
        display: inline-block;
        margin-left: calc(-1 * var(--article-body-bullet-width));
        width: var(--article-body-bullet-width);
        text-align: var(--article-body-bullet-text-align);
        vertical-align: var(--article-body-bullet-vertical-align);
        font-size: var(--article-body-bullet-font-size);
        line-height: var(--article-body-bullet-line-height);
      }
    }

    ul {
      margin: var(--article-body-ul-ul-margin);
      padding: var(--article-body-ul-ul-padding);
    }

    ol {
      margin-top: 24px;
    }
  }

  p,
  .body-list-el > li {
    a {
      color: var(--article-body-list-link-color);

      body.today & {
        border-bottom: var(--article-body-list-link-underline-width) solid var(--article-body-list-link-underline-color);
        padding-bottom: var(--article-body-list-link-underline-padding);
      }

      &:hover {
        color: var(--article-body-list-link-hover-color);
        opacity: var(--article-body-list-hover-opacity);
      }

      &:active {
        opacity: var(--article-body-list-active-opacity);
      }
    }

    // Remove margin at beginning of .article-body wrapper of components
    &:first-of-type:not(.liftout__text) {
      margin-top: 0;
    }

    // Remove margin at end of .article-body wrapper of components
    &:last-of-type {
      :not(.listicle-product__description) {
        margin-bottom: var(--article-body-last-paragraph-margin-btm);
      }
    }
  }
}

.article-body--right-rail-container {
  display: contents;
}

.article-body__right-rail {
  float: var(--article-body--right-rail--float);

  .article-body__grid--container-live-blog:not(.article-body__grid--container-no-main-image) & {
    @include h.breakpoint-l {
      margin-top: var(--article-body--live-blog--no-main-image--margin-top);
    }
  }
}

.article-body__non-sticky {
  position: relative !important;
  top: 0 !important;
}

.article-body__right-rail--ad {
  @include h.breakpoint-l {
    position: sticky;
    top: h.rem(90px);
    margin-top: h.rem(24px);
    display: flex;
    justify-content: center;
    transition: top 200ms ease-in;
  }

  @include h.ie {
    min-height: 600px;
  }

  .HFSBumped & {
    top: h.rem(90px + $globalNavbarHeight);
  }
}

.article-body__box-inline-ad {
  width: calc(100% + (2 * #{h.$outer-gutter}));
  background-color: var(--article-body-box-inline-ad-background);
  text-align: center;
  padding: h.rem(10px 0);
  margin: h.rem(30px 0 30px h.$neg-outer-gutter);

  @include h.breakpoint-m {
    width: calc(100% + (2 * #{h.$gutter-tablet}) + (2 * (100% - 9 * (#{h.$gutter-tablet})) / 10));
    margin-left: calc(-4vw - (100% - 9 * (#{h.$gutter-tablet})) / 10);
  }

  @include h.breakpoint-l {
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: none;
  }
}

.article-body__ecommerce-enabled {
  p.ecom {
    display: block;
    color: var(--article-body-ecommerce-enabled-text);

    a {
      border-bottom: 1px solid;
    }
  }

  .article-body__content {
    .body-heading a {
      color: var(--article-body-ecommerce-enabled-link);

      &:hover {
        opacity: var(--article-body-heading-ecommerce-link-hover-opacity);
      }

      &:active {
        opacity: var(--article-body-heading-ecommerce-link-active-opacity);
      }

      &:visited {
        opacity: 1;
      }
    }
  }
}

a.article-source__link {
  color: var(--article-body--source--link-color);

  &:hover {
    opacity: 0.7;
  }
}

.articleDevelopingTag {
  margin: h.rem(48px) 0;
}

.articleDevelopingTag__content {
  padding: var(--article-body-developing-tag-padding);
  font-family: var(--article-body-developing-tag-font-family);
  font-size: var(--article-body-developing-tag-font-size);
  line-height: var(--article-body-developing-tag-line-height);
  font-weight: var(--article-body-developing-tag-font-weight);
  border-top: var(--article-body-developing-tag-border-top);
  border-bottom: var(--article-body-developing-tag-border-bottom);

  .articleDevelopingTag__content--red {
    color: var(--article-body-developing-tag-color);
  }
}

.articleDevelopingTag__stripe-border {
  width: 50%;
  display: var(--article-body-developing-stripe-border-display);

  @include h.breakpoint-m {
    width: 30%;
  }
  height: h.rem(9px);
  padding: 0 h.rem(4px) h.rem(4px) 0;
  background-image:
    linear-gradient(
      135deg,
      var(--article-body-developing-stripe-border-color) 25%,
      transparent 25%,
      transparent 50%,
      var(--article-body-developing-stripe-border-color) 50%,
      var(--article-body-developing-stripe-border-color) 75%,
      transparent 75%,
      transparent 100%
    );
  background-size: 5.66px 5.66px;
}

.articleDevelopingTag__stripe-border--solid {
  border-top: 9px solid var(--article-body-developing-solid-border-color);
  width: var(--space-60);
}

.article-body__date-source {
  color: var(--article-body--date-source--color);
  font-family: var(--article-body--date-source--font-family);
  font-size: var(--article-body--date-source--font-size);
  font-weight: var(--article-body--date-source--font-weight);
  letter-spacing: var(--article-body--date-source--letter-spacing);
  line-height: var(--article-body--date-source--line-height);
  margin: var(--article-body--date-source--margin);
}

.body-bottom-recommended {
  display: contents;
}

.articlePage .body-hori-rule {
  width: 100px;
  border-style: solid;
  border-width: 2.5px;
}

body.today.articlePage {
  .body-hori-rule {
    border-color: #ffb186;
  }

  .article-body__last-section {
    margin-bottom: 0;
  }

  @include h.breakpoint-s-only {
    .article-body__last-section {
      margin-bottom: h.rem(70px);
    }
  }
}

body.news.articlePage {
  .body-hori-rule {
    border-color: #102039;
  }
}

body.msnbc.articlePage {
  .body-hori-rule {
    border-color: #3061ff;
  }
}
